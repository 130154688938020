// Given a time in milliseconds (from the start of a run), return a string in the format of "mm:ss".
export const formatTime = (timeInMs) => {
  const seconds = Math.floor(timeInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const formatActionLabel = (action) => {
  const actionMap = {
    no_action: 'No Action',
    analyze_page: 'Read',
    click_element: 'Click',
    hover_element: 'Hover',
    scroll_element: 'Scroll',
    fill_text_element: 'Type',
    fill_date_element: 'Pick date',
    fill_color_element: 'Pick color',
    fill_range_element: 'Pick range',
    check_uncheck_element: 'Update checkbox',
    select_option_element: 'Select option',
    upload_file_element: 'Upload file',
    reload_page: 'Reload page',
    user_session_summary: 'Run summary',
  };
  return `${actionMap[action] || action}`;
};
