import { Controller } from '@hotwired/stimulus';
import { useWindowResize } from 'stimulus-use';
import rrwebPlayer from 'rrweb-player';
import React from 'react';
import { createRoot } from 'react-dom/client';

import Controls from '../player/Controls.js';
import FrameData from '../player/FrameData.js';
import Actions from '../player/Actions.js';

export default class extends Controller {
  static values = { recordingUrl: String, width: Number, height: Number, actor: String };
  static targets = ['video', 'controls', 'title', 'favicon', 'url', 'actions', 'debug'];

  async connect() {
    if (!this.hasRecordingUrlValue) return;

    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    const query = new URLSearchParams(window.location.search);
    const debug = query.has('debug');

    const response = await fetch(this.recordingUrlValue, { cache: 'no-cache' });
    const RECORDING = response.ok ? await response.json() : {};
    if (debug) console.log(RECORDING);

    // TEMP: update legacy recordings
    RECORDING.actions ||= [];
    RECORDING.frames ||= [];
    RECORDING.actions.forEach((action) => {
      action.interaction_type ||= action.interactionType || action.action_type || action.action;
      action.action_data ||= action.actionData || {};
      action.action_purpose ||= action.actionPurpose;
      action.decision_rationale ||= action.decisionRationale;
    });

    this.video = new rrwebPlayer({
      target: this.videoTarget,
      props: {
        width: this.widthValue,
        height: this.heightValue,
        events: RECORDING.events,
        showController: false,
        showDebug: debug,
        showWarning: debug,
        useVirtualDom: query.has('virtual-dom'),
        mouseTail: { lineWidth: 6, lineCap: 'round', strokeStyle: '#0044F3', duration: 750 },
        insertStyleRules: debug ? [] : ['synthetictraffic-canvas { display:none }'],
      },
    });

    this.replayer = this.video.getReplayer();
    this.video.addEventListener('custom-event', (event) => {
      console.log(event.data);
    });

    // init mouse at center
    if (this.replayer?.mouse) {
      this.replayer.mouse.style.left = `${this.widthValue * 0.5}px`;
      this.replayer.mouse.style.top = `${this.heightValue * 0.5}px`;
    }

    this.controls = createRoot(this.controlsTarget);
    this.controls.render(<Controls debug={debug} video={this.video} actions={RECORDING.actions} />);

    this.title = createRoot(this.titleTarget);
    this.title.render(<FrameData debug={debug} video={this.video} frames={RECORDING.frames} field={'title'} />);

    this.favicon = createRoot(this.faviconTarget);
    this.favicon.render(<FrameData debug={debug} video={this.video} frames={RECORDING.frames} field={'favicon'} />);

    this.url = createRoot(this.urlTarget);
    this.url.render(<FrameData debug={debug} video={this.video} frames={RECORDING.frames} field={'url'} />);

    this.actions = createRoot(this.actionsTarget);
    this.actions.render(
      <Actions
        debug={debug}
        video={this.video}
        actions={RECORDING.actions}
        actor={this.hasActorValue ? JSON.parse(this.actorValue) : null}
      />,
    );

    if (debug) {
      this.debug = createRoot(this.debugTarget);
      this.debug.render(<FrameData video={this.video} frames={RECORDING.frames} field={'debug'} />);
    }

    useWindowResize(this);
    setTimeout(() => this.computeRatio(), 50);
  }

  computeRatio = () => {
    if (!this.videoTarget) return;

    const player = this.videoTarget.querySelector('.rr-player');
    const frame = this.videoTarget.querySelector('.rr-player__frame');
    const wrapper = this.videoTarget.querySelector('.replayer-wrapper');

    this.videoTarget.style.aspectRatio = `${this.widthValue}/${this.heightValue}`;

    if (player) {
      player.style.aspectRatio = `${this.widthValue}/${this.heightValue}`;
      player.style.width = null;
      player.style.height = null;
    }

    if (frame) {
      frame.style.aspectRatio = `${this.widthValue}/${this.heightValue}`;
      frame.style.width = null;
      frame.style.height = null;
    }

    if (wrapper) wrapper.style.scale = this.videoTarget.getBoundingClientRect().width / this.widthValue;
  };

  windowResize() {
    this.computeRatio();
  }
}
