import React, { forwardRef, useEffect, useState } from 'react';

import { formatTime, formatActionLabel } from '../lib/utils';

import ThumbsUp from '../../assets/images/thumbs-up.svg';
import ThumbsDown from '../../assets/images/thumbs-down.svg';

const DebugWrapper = ({ children, className }) => (
  <div className={`text-xxs bg-ruby-100 border-ruby-200 mt-2 overflow-hidden rounded-lg border font-mono text-rose-700 ${className}`}>
    {children}
  </div>
);

const UserSessionSummaryBubble = ({ debug, action }) => {
  // style color based on status
  let badge = <></>;
  let classes = 'text-xxs ml-2 rounded-sm px-1 font-mono font-bold uppercase leading-snug text-white';
  if (action.user_session_summary.status === 'satisfied_objective') badge = <div className={`${classes} bg-green-600`}>Success</div>;
  if (action.user_session_summary.status === 'raise_issue') badge = <div className={`${classes} bg-red-600`}>Failed</div>;
  if (action.user_session_summary.status === 'timeout_elapsed') badge = <div className={`${classes} bg-gray-600`}>Timed out</div>;
  if (action.user_session_summary.status === 'session_aborted') badge = <div className={`${classes} bg-yellow-600`}>Aborted</div>;

  return (
    <>
      <div className="flex flex-col gap-y-6 pb-2 text-sm text-gray-500">
        <div>
          <div className="mb-0.5 font-medium text-black">Summary</div>
          <div>{action.user_session_summary.session_summary}</div>
        </div>

        <div>
          <div className="flex items-center">
            <div className="mb-0.5 font-medium text-black">Objective</div>
            {badge}
          </div>
          <div>{action.user_session_summary.objective_resolution}</div>
        </div>

        <div>
          <div className="mb-0.5 font-medium text-black">Experience</div>
          <div>{action.user_session_summary.experience_summary}</div>
        </div>

        {'difficulty_level' in action.user_session_summary && (
          <div className="flex justify-between gap-5">
            {'difficulty_level' in action.user_session_summary && (
              <div>
                <div className="mb-0.5 font-medium text-black">Difficulty</div>
                {Math.floor(action.user_session_summary.difficulty_level * 100)}%
              </div>
            )}
            {'frustration_level' in action.user_session_summary && (
              <div>
                <div className="mb-0.5 font-medium text-black">Frustration</div>
                {Math.floor(action.user_session_summary.frustration_level * 100)}%
              </div>
            )}
            {'enjoyment_level' in action.user_session_summary && (
              <div>
                <div className="mb-0.5 font-medium text-black">Enjoyment</div>
                {Math.floor(action.user_session_summary.enjoyment_level * 100)}%
              </div>
            )}
          </div>
        )}

        {debug && (
          <DebugWrapper>
            <div className="whitespace-pre-wrap p-2">{JSON.stringify(action, null, 2)}</div>
          </DebugWrapper>
        )}
      </div>
    </>
  );
};

const DefaultBubble = ({ debug, action, startTime }) => {
  return (
    <>
      <div className="mb-1 text-sm">{action.action_purpose}</div>

      {action.decision_rationale && (
        <div className="ml-2 mr-2 border-l border-gray-300 pl-4 text-xs leading-4 text-gray-500">{action.decision_rationale}</div>
      )}

      {action.action_data?.clear_dirty_field && (
        <div className="border-b border-gray-200 py-4 last:border-b-0">
          <div className="text-xs font-medium text-gray-600">
            <div>I will clear the existing value</div>
          </div>
        </div>
      )}

      {(action.action_data?.field_value || action.action_data?.field_values) && (
        <div className="rounded-md bg-gray-100 px-4">
          {[action.action_data?.field_values, action.action_data?.field_value]
            .flat()
            .filter(Boolean)
            .map((field_value, i) => (
              <div key={`field-value-${i}`} className="border-b border-gray-200 py-4 last:border-b-0">
                <div className="text-xs font-medium text-gray-600">
                  <div className="justify-center pt-2 font-mono text-xs font-normal text-gray-500 first:pt-0">{field_value}</div>
                </div>
              </div>
            ))}
        </div>
      )}

      {action.action_data?.press_enter_to_submit && (
        <div className="border-b border-gray-200 py-4 last:border-b-0">
          <div className="text-xs font-medium text-gray-600">
            <div>I will press Enter to submit the form</div>
          </div>
        </div>
      )}

      <div className="text-xxs pt-1.5 font-mono text-gray-500">
        {formatActionLabel(action.interaction_type)} / {formatTime(action.timestamp - startTime)}
        {debug && (
          <DebugWrapper>
            <div className="mono p-2">{action.elementId}</div>
            {action.thumbnail && <img src={action.thumbnail.base64} className="border-ruby-200 border-t" />}
            <div className="whitespace-pre-wrap p-2">{JSON.stringify(action.action_data, null, 2)}</div>
          </DebugWrapper>
        )}
      </div>
    </>
  );
};

// ForwardRef becuase we need to access the DOM element directly from the parent.
const ActionBubble = forwardRef(({ debug, action, startTime, isClosest, translateY, isVisible, onClick, actor }, ref) => {
  // isInitialRender keeps track of whether the component is being rendered for the first time.
  // If it is we won't transition in the thumbs - they should be visible from the start.
  // Only really matters for the first 'current' bubble, but no harm in applying it to all.
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) setTimeout(() => setIsInitialRender(false), 100);
  }, [isClosest, setIsInitialRender, isInitialRender]);

  let Bubble = DefaultBubble;
  if (action.interaction_type === 'user_session_summary') Bubble = UserSessionSummaryBubble;

  return (
    <div
      onClick={() => onClick(action.timestamp)}
      ref={ref}
      className={`${isClosest ? 'active' : ''} action-bubble ${isClosest ? '' : 'hidden md:block'} will-change-opacity w-full md:absolute md:left-0 md:top-0 md:translate-y-[var(--translate-y)] ${isVisible ? 'md:transition-all md:duration-500 md:will-change-transform' : ''} md:before:absolute md:before:inset-0 md:before:z-[2] md:before:bg-gradient-to-t md:before:from-white/80 md:before:to-white md:before:opacity-0 md:before:transition-opacity md:before:duration-500 md:before:content-['']`}
      style={{ '--translate-y': `${translateY}px` }}
    >
      <div data-timestamp={action.timestamp} className="relative rounded-lg bg-gray-200 p-4 md:ml-[14px]">
        <div className="flex flex-col gap-y-3">
          {actor && (
            <div className="flex items-center gap-x-2 text-sm">
              {actor.avatar_url && <img src={actor.avatar_url} className="inline-block h-6 w-6 flex-shrink-0 rounded-full object-cover" />}
              <div className="font-medium">{actor.full_name}</div>
              <div className="font-mono text-xs font-medium text-gray-500">
                {actor.age}/{actor.gender[0]}/{actor.location_region}
              </div>
            </div>
          )}
          <Bubble debug={debug} action={action} startTime={startTime} actor={actor} />
        </div>
      </div>
      <footer
        className={`mt-2 flex items-center justify-end ${
          isInitialRender
            ? isClosest
              ? 'opacity-100'
              : 'opacity-0'
            : `transition-opacity duration-200 ${isClosest ? 'opacity-100 delay-200' : 'opacity-0'}`
        }`}
      >
        <ThumbsUp className="stroke-gray-500 hover:cursor-pointer hover:stroke-gray-700" />
        <ThumbsDown className="ml-2 stroke-gray-500 hover:cursor-pointer hover:stroke-gray-700" />
      </footer>
    </div>
  );
});

export default ActionBubble;
