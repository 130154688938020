import React, { useState, useEffect } from 'react';

const FrameData = ({ video, frames, field }) => {
  const [startTime, setStartTime] = useState(0);
  const [time, setTime] = useState(0);
  const [frame, setFrame] = useState(null);

  useEffect(() => {
    setStartTime(video.getMetaData().startTime);

    const _setTime = (e) => setTime(e.payload);
    video.addEventListener('ui-update-current-time', _setTime);
    // return () => video.removeEventListener('ui-update-current-time', _setTime);
  }, [video, frames]);

  useEffect(() => {
    const curr = startTime + time;
    for (let i = frames.length - 1; i >= 0; i--) {
      if (curr >= frames[i].timestamp) {
        return setFrame(frames[i]);
      }
    }
  }, [frames, startTime, time]);

  if (!frame) return;
  if (field === 'favicon') return <img src={frame[field]} width={16} height={16} />;
  if (field === 'debug') return <div>{JSON.stringify({ currTime: time + startTime, startTime, time, frame }, null, 2)}</div>;
  return frame[field];
};

export default FrameData;
