import { Controller } from '@hotwired/stimulus';
import { useResize } from 'stimulus-use';

// Connects to data-controller="fit-text"
export default class extends Controller {
  static targets = ['text', 'linebreak'];

  connect() {
    useResize(this);
  }

  resize({ width }) {
    this.textTarget.style.fontSize = `${this.fontSize}px`;

    this.linebreakTargets.forEach((linebreak) => {
      if (!linebreak.dataset.breakpoint) return;
      linebreak.style.display = width > parseInt(linebreak.dataset.breakpoint) ? null : 'block';
    });

    this.textTarget.style.fontSize = `${(this.fontSize = 1)}px`;

    while (this.textTarget.scrollWidth <= Math.round(width)) {
      this.textTarget.style.fontSize = `${(this.fontSize += 0.1)}px`;
    }

    this.textTarget.style.fontSize = `${(this.fontSize -= 0.1)}px`;
  }
}
