import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

// Connects to data-controller="tippy"
export default class extends Controller {
  static values = { content: String, placement: String };

  connect() {
    const options = {
      content: this.contentValue,
    };

    if (this.hasPlacementValue) options.placement = this.placementValue;

    tippy(this.element, options);
  }
}
